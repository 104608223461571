import axios from "axios";
import { Toast } from 'vant';
import store from "@/store";


 

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 5000 // request timeout
});



service.interceptors.request.use(
    config => {
        // if (store.getters.token) {
        //     config.headers["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MjU0NjcwNjMsImlzcyI6ImxvZ2luIiwidWlkIjoxNTcyNn0.O4YHp_Jpl5vvp0_14-mNZGTpP-g8WVm_USZ5CIcKk-E`;
        // }
        // config.headers["Authorization"] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6IiVFNiVCNSU4QiVFOCVBRiU5NSVFNyVBRSVBMSVFNyU5MCU4NiVFNSU5MSU5OCIsInVzZXJJZCI6IjEyNTEiLCJhcHBDbGllbnRJZCI6ImEzZjIzMTBjLTc3ZDgtNDc1OS04MTI2LWEyNmMxYTlkNDRhNyIsImlzcyI6IjA5OGY2YmNkNDYyMWQzNzMyZmRlNGU4MzI2MjdiNGY2IiwiaWF0IjoxNjI1NzI3ODg3LCJhdWQiOiJlZGExNzgyMjA0Y2Y0MWVmYWNhMWUwNTFjY2M2MTBiZTYyYWNkY2YyNGMwOWYwMTFmMzQzNTgzYzQxY2ZiOTNmIiwiZXhwIjoxNjU3MjYzODg3LCJuYmYiOjE2MjU3Mjc4ODd9.WFt19UB1D7YcRz9kaNHiZ0lzC9RT3oukNkn_YHzstYk`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code == 1||res.code==2) {
            return res;
        } else {
            Toast.success(res.message || res.msg)
            return Promise.reject(new Error(res.msg || "Error"));
        }
    },
    error => {
        
        if(error.message=="Request failed with status code 401"){
            Toast.fail( '请重新登陆!');
            return Promise.reject(error.message);
        }
        Toast.fail(error.message || '请求失败！');
        return Promise.reject(error.message);
    }
);

export default service;
