import request from '@/utils/request'
import storage from "@/utils/storage";

//发送手机验证码
export function getCode(data) {
    return request({
      url: 'api/sms/sendsms',
      method: 'post',
      data
    })
  }
// 登录
export function login(data) {
    return request({
      url: 'api/user/mobilelogin',
      method: 'post',
      data
    })
  }
//首页banner
export function getBanner(){
    return request({
        url:'api/index/banner',
        method: "get",
    })
}
// 会场列表
export function getVenue(){
  return request({
      url:'api/index/venue',
      method: "get",
  })
}

// 会场详情
export function getVenueContent(params){
  return request({
      url:'api/index/venuecontent',
      method: "get",
      params
  })
}

//嘉宾列表
export function getGuest(){
  return request({
    url:"api/index/guest",
    method:"get"
  })
}

//用户信息
export function getUserinfo(){
  return request({
    headers: {
      token: localStorage.getItem("token")
    },
    url:"api/user/getUserinfo",
    method:"get"
  })
}
//获取配置信息
export function getConfig(params){
  return request({
    url:"api/index/getConfig",
    method:"get",
    params
  })
}
//获取顾问信息
export function getAdviser(params){
  return request({
    url:"api/index/adviser",
    method:"get",
    params
  })
}
//获取筹备组信息
export function getPreparation(params){
  return request({
    url:"api/index/preparation",
    method:"get",
    params
  })
}